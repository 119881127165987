// 引入抽屉详情组件
import UserManageDrawer from "./components/userManageDrawer/index.vue";
// 引入api
import { fun_PostAction } from "@/api";
// 引入转译方法
import { fun_UserState, fun_UserRole, fun_GetKeys } from "@/utils/validate";
// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      // 列表loading初始状态
      loading: false,
      // 查询表单字段
      form: {
        name: null, // 用户名称
        realName: null // 真实姓名
        // roleId: null // 角色
      },
      // 查询表单
      formSearchList: [
        {
          type: "input",
          label: "用户名称：",
          value: "name",
          placeholder: "请输入"
        },
        {
          type: "input",
          label: "真实姓名：",
          value: "realName",
          placeholder: "请输入"
        }
        // {
        //   type: "select",
        //   label: "角色：",
        //   value: "roleId",
        //   placeholder: "请选择",
        //   queryParams: {
        //     sqlId: "6515d115-1e37-423a-91d4-80a14fe3c494"
        //   }
        // }
      ],
      // 表头按钮组
      btnList: [
        {
          name: "添加",
          type: "success",
          icon: "document-add"
        },
        {
          name: "修改",
          type: "primary",
          icon: "edit"
        },
        {
          name: "删除",
          type: "danger",
          icon: "delete"
        }
      ],
      // 表格基础数据
      tableColumns: [
        {
          label: "用户名称",
          prop: "name"
        },
        {
          label: "真实姓名",
          prop: "realName"
        },
        {
          label: "角色",
          prop: "roleId"
        },
        {
          label: "用户状态",
          prop: "userState"
        }
      ],
      // 表格数据
      tableData: [],
      // 分页
      reqPageData: {
        current: 1, // 页码
        size: 10 // 每页条数
      },
      total: 0, // 总条数
      // 勾选的数据
      multipleSelection: []
    };
  },

  // 注册组件
  components: {
    // 抽屉详情组件
    UserManageDrawer
  },

  // 模板编译完成
  mounted() {
    // 获取一览表格数据
    this.fun_GetList();
  },

  // 自定义方法
  methods: {
    /**
     * 获取一览表格数据
     * @author 滕威
     */
    async fun_GetList() {
      // 打开loading
      this.loading = true;
      // 请求，获取结果集
      let res = await fun_PostAction("/user/list", {
        ...this.form,
        ...this.reqPageData
      });
      // 解构res.data
      const { result, data, msg } = res.data;
      // 判断请求是否成功
      if (result) {
        // 解构data
        const { total, records } = data;
        // 遍历列表数据
        records.map(e => {
          // 转译用户状态
          e.userState = fun_UserState(e.userState);
          e.roleId = fun_UserRole(e.roleId);
        });
        // 回显table
        this.tableData = records;
        // 获取分页总条数
        this.total = total;
      } else {
        // 提示请求失败消息
        this.$message.error(msg);
      }
      // 关闭loading
      this.loading = false;
    },
    /**
     * 点击修改按钮
     * @author 滕威
     */
    fun_Edit() {
      // 判断是否勾选
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        // 勾选超过1条
        if (this.multipleSelection.length > 1) {
          // 提示，并清除选中数据
          this.$alert("只能选择一条数据！", "warning").then(() => {
            // 清除选中数据
            this.$refs.list.$refs.multipleTable.clearSelection();
          });
        } else {
          // 打开抽屉
          this.$refs.userManageDrawer.drawerStatus = true;
          this.$refs.userManageDrawer.fun_GetDetails(
            this.multipleSelection[0].id
          );
        }
      } else {
        // 未勾选，则提示
        this.$alert("请选择一条数据！", "warning");
      }
    },
    /**
     * 点击删除按钮
     * @author 滕威
     */
    fun_Del() {
      // 判断是否勾选
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        // 弹出询问confirm
        this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          // 获取keys
          const keys = fun_GetKeys(this.multipleSelection);
          // 发起请求，获取结果集
          fun_PostAction("/user/delete?keys=" + keys).then(res => {
            // 解构res.data
            const { result, msg } = res.data;
            // 判断请求是否成功
            if (result) {
              // 查询列表
              this.fun_GetList();
              // 提示删除成功
              this.$message.success(msg);
            } else {
              // 请求失败，提示信息
              this.$message.error(msg);
            }
          });
        });
      } else {
        // 未勾选，则提示
        this.$alert("请选择一条数据！", "warning");
      }
    },
    /**
     * 查询
     * @author 滕威
     */
    fun_Search() {
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变每页条数执行的函数
     * @params e 每页条数
     * @author 滕威
     */
    fun_LimitChange(e) {
      // 获取每页显示条数
      this.reqPageData.size = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变页码执行的函数
     * @params e 页码数
     * @author 滕威
     */
    fun_PageChange(e) {
      // 获取当前页码
      this.reqPageData.current = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 表头按钮事件
     * @params e 按钮name
     * @author 滕威
     */
    fun_click(e) {
      switch (e) {
        case "添加":
          // 打开抽屉
          this.$refs.userManageDrawer.drawerStatus = true;
          // 添加时，清空id
          this.$refs.userManageDrawer.form.id = null;
          break;
        case "修改":
          // 调用修改方法
          this.fun_Edit();
          break;
        default:
          // 调用删除方法
          this.fun_Del();
      }
    },
    /**
     * 勾选事件
     * @params e 选中的数据
     * @author 滕威
     */
    fun_HandleSelectionChange(e) {
      // 将勾选的value值赋给multipleSelection数组
      this.multipleSelection = e;
    },
    fun_GetSelectVal(e) {
      this.form.roleId = e;
    }
  }
};
